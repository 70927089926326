@import "../styles/colors";
@import "../styles/grid";

.footer-container {
    background-color: $light-gray-color;

    .footer-1 {
        padding-top: 80px;
        padding-bottom: 40px;
    }
    .footer-2 {
        padding-top: 40px;
        padding-bottom: 80px;

        .col {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .category-header {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 22px;
        }

        a {
            color: black;
            text-decoration: none;
            font-size: 16px;
        }
    }

    .footer-3 {
        background-color: black;
        color: white;
        padding-top: 70px;
        padding-bottom: 70px;
        font-size: 16px;

        .content-menu {
            display: flex;
            justify-content: center;

            a {
                color: white;
                text-decoration: none;
                font-weight: bold;
                margin-right: 15px;

                .logged-in {
                    font-weight: normal;
                }
            }
        }
    }

    &.bottom-margin {
        padding: 0;

        .bottom-margin-container {
            background: url("../assets/kratka.svg") repeat;
            height: 80px;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}
