@import "../../styles/colors";
@import "../../styles/grid";

.navbar-nav {
    .nav-link {
        color: white;
        &:hover,
        &:focus,
        &.active {
            color: #aaaaaa !important;
            transition: none !important;
        }
    }
}
