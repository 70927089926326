@import "../styles/colors";
@import "../styles/grid";

$hamburger-types: (collapse);
$hamburger-layer-color: white;
$hamburger-active-layer-color: white;

@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

.header {
    background-color: black;
    color: white;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    .container-fluid {
        @media (min-width: $grid-breakpoints-lg) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .navbar-brand {
            padding: 0;
        }

        .navbar-toggler {
            color: white !important;
            outline: 0;
            border: none;
            &:hover,
            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        a {
            color: white;
            text-transform: uppercase;
        }
    }
}
