.message-bar {
    padding-left: 0;
    padding-right: 0;

    .alert {
        margin: 0;

        a {
            color: inherit;
        }
    }
}
