$body-bg: white;
$body-color: black;
// --bs-btn-disabled-border-color: black;
$primary: black;

@import "~bootstrap/scss/bootstrap";
@import "styles/colors";
@import "styles/grid";

// :root {
//     $body-bg: #000;
//     $body-color: #111;
//     --bs-btn-disabled-border-color: black;
//     $primary: black;
// }

.btn {
    border: 2px solid black;
    border-radius: 0;
    text-transform: uppercase;
}

.btn-link {
    color: black;
}

.form-control {
    border: 2px solid black;
    border-radius: 0;
}
