@import "styles/colors";
@import "styles/grid";

* {
    box-sizing: border-box;
}

html {
    background-color: black;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-weight: normal;
    color: black;
    background-color: white;
    background-repeat: repeat;
    margin: 0;
    padding: 0;
}

.layout {
    display: flex;
    min-height: 100vh;
}
